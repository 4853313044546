.mobile_app_wrapper {
    .mobile_app {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        .yellow_circle {
            position: absolute;
            width: 700px;
            height: 720px;
            right: -200px;
            bottom: -300px;
            pointer-events: none;
        }
        @media (max-width: $tablet) {
            flex-direction: column-reverse;
        }
        @media (max-width: $mobile) {
            flex-direction: column;
        }

        img {
            width: 100%;
        }

        .mobile_app_right {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
            margin-right: 100px;
            @media (max-width: $tablet) {
            }
            @media (max-width: $tablet) {
                gap: 100px;
                margin-left: 100px;
            }
            @media (max-width: $mobile) {
                flex-direction: column;
                gap: 60px;
            }
            img {
                width: 100%;
                max-width: 100%;
            }
            .column {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: 20px;
            }
        }
    }
    #title_mobile {
        @include adaptive-font(36, 16);
    }
    .paralax_mobile {
        opacity: 0;
        transform: translateX(-30%);
        transition:
            transform 1s,
            opacity 1s;
    }
    .visible {
        opacity: 1;
        transform: translateX(0);
    }
}
