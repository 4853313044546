.voting_wrapper {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    margin-bottom: 50px;
    @media only screen and (max-width: $tablet) {
        margin-bottom: 100px;
    }
    .splide__list {
        max-width: 940px !important;
        @media only screen and (max-width: 1050px) {
            max-width: 840px !important;
        }
        @media only screen and (max-width: $tablet) {
            max-width: calc(100vw - 30px) !important;
        }
    }
    .splide__slide {
        background-color: $dark-light !important;
        border-radius: 10px !important;
        width: 100% !important;
    }
    .splide__pagination__page {
        opacity: 1 !important;
        width: 15px !important;
        height: 15px !important;
        background-color: transparent !important;
        border: 1px solid $blue !important;
        bottom: -50px;
        margin-right: 10px;
        @media only screen and (max-width: $tablet) {
            bottom: -80px;
        }
        @media only screen and (max-width: 500px) {
            bottom: -100px;
        }
    }
    .splide__pagination__page.is-active {
        background-color: $blue !important;
    }
    .voting_card_wrapper {
        padding: 30px 60px;
        color: $gray;
        font-weight: 300;
        @media only screen and (max-width: 1050px) {
            padding: 30px 20px;
        }
        @media only screen and (max-width: $tablet) {
        }
        .voting_card_title {
            color: $gray;
            text-align: center;
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .voting_card_description {
            font-weight: 300;
            line-height: 20px;
            margin-bottom: 20px;
            white-space: pre-line;
        }
        .block_number {
            font-size: 16px;
            font-weight: 700;
        }
        .min_amount_ovg_number {
            font-size: 16px;
            font-weight: 700;
            color: $blue;
        }
        .start_block {
            font-size: 16px;
            font-weight: 300;
        }
        .end_block {
            font-size: 16px;
            font-weight: 300;
        }
        .status_green {
            color: $green;
            font-size: 16px;
            font-weight: 700;
        }
        .button_wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%, -50%);
            .polls_button {
                background-color: transparent;
                color: $white;

                &:hover {
                    background-color: $dark-blue;
                }
            }
        }

        .content_wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 25px;
            @media only screen and (max-width: $mobile) {
                flex-direction: column;
            }
        }
        .info_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 15px;
            @media only screen and (max-width: $mobile) {
                align-self: flex-start;
            }
        }
        .info_punkt {
            display: flex;
            gap: 3px;
        }
        .voting_card_circle_wrapper {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            .circle_info {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }
            .min_users_voted {
                font-size: 16px;
                text-align: center;
                white-space: nowrap;
                margin-right: 2px;
            }

            .min_users_voted_number {
                font-weight: 500;
                line-height: 16px;
            }
            .d_none {
                display: none;
            }
        }
        .circular_progress {
            width: 212px;
            height: 212px;
            position: relative;
            margin: 0 auto;
            background: conic-gradient($blue 3.6deg, #222a33 0deg);
            border-radius: 50%;
        }

        .circular_progress::before {
            content: '';
            position: absolute;
            height: 180px;
            width: 180px;
            border-radius: 50%;
            background: #222a33;
            top: 16px;
            left: 16px;
        }

        .circular_progress::after {
            content: '';
            position: absolute;
            height: 197px;
            width: 197px;
            border-radius: 50%;
            background: transparent;
            border: 1px solid $blue;
            top: 6px;
            left: 6px;
        }
        .min_users_voted_wrapper {
            display: flex;
        }
        .users_voted {
            color: $blue;
            font-size: 35px;
            font-weight: 700;
            margin-bottom: 10px;
        }
    }
}
.arrow_prev {
    width: 20px;
    transform: rotate(45deg);
    left: -50px !important;
    @media only screen and (max-width: $tablet) {
        left: 150px !important;
        bottom: -300px !important;
        position: absolute;
    }
    @media only screen and (max-width: 700px) {
        left: 100px !important;
    }
    @media only screen and (max-width: $mobile) {
        bottom: -370px !important;
    }
    @media only screen and (max-width: 500px) {
        left: 50px !important;
        bottom: -430px !important;
    }
    @media only screen and (max-width: 400px) {
        left: 50px !important;
        bottom: -480px !important;
    }
}
.arrow_next {
    width: 20px;
    transform: rotate(-45deg);
    right: -50px !important;
    @media only screen and (max-width: $tablet) {
        right: 150px !important;
        bottom: -300px !important;
        position: absolute;
    }
    @media only screen and (max-width: 700px) {
        right: 100px !important;
    }
    @media only screen and (max-width: $mobile) {
        bottom: -370px !important;
    }
    @media only screen and (max-width: 500px) {
        right: 50px !important;
        bottom: -430px !important;
    }
    @media only screen and (max-width: 400px) {
        right: 50px !important;
        bottom: -480px !important;
    }
}
.splide__arrow {
    background-color: transparent !important;
    opacity: 1 !important;
}
#voting_start {
    margin-bottom: 30px;

    .title {
        @include adaptive-font(36, 20);
    }
    .title_back {
        @include adaptive-font(90, 40);
    }
}
