.header {
    width: 100vw;
    height: 76px;
    margin: 0 auto;
    position: fixed;
    background-color: $dark-light;
    z-index: 10;

    .nav {
        max-width: 1050px;
        height: 76px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;

        @media (max-width: $tablet) {
            min-width: 100%;
            padding: 0 20px;
            justify-content: space-between;
        }
        #voting_li {
            position: relative;
            display: none;
            #voting_counter {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -15px;
                right: 8px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $blue;
                color: $dark-light;
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
    a {
        color: $gray;
        &:hover {
            color: $blue;
        }
    }
    .nav-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(30px, 1fr));
        width: 100%;
        @media (max-width: 1200px) {
        }
        li {
            text-align: center;
        }
    }

    img {
        width: 180px;
        margin-right: 20px;
        cursor: pointer;
    }

    .button_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        @media (max-width: 1200px) {
            gap: 10px;
        }
        a {
            color: $white;
        }
    }
    .disappear_on_tablet {
        @media (max-width: $tablet) {
            display: none;
        }
    }
    .burger_menu_button {
        width: 30px;
        height: 20px;
        cursor: pointer;
        @media (min-width: $tablet) {
            display: none;
        }
    }
}
