.grafic_bubbles_wrapper {
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
    white-space: pre-line;
    .grafic {
        max-width: 1100px;
        width: 100%;
        height: 430px;
        background-color: transparent;
        position: relative;
        @media (max-width: $tablet) {
            margin-left: -50px;
        }
        @media (max-width: 1100px) {
            transform: scale(0.9);
            margin: 0px 20px 0px 20px;
        }
        @media (max-width: 1000px) {
            transform: scale(0.9);
            margin: -30px 20px -50px 20px;
        }
        @media (max-width: 900px) {
            transform: scale(0.8);
            margin: -50px 20px -100px 20px;
        }
        @media (max-width: 800px) {
            transform: scale(0.7);
            margin: -90px 20px -100px 20px;
        }
        @media (max-width: 700px) {
            transform: scale(0.6);
            margin: -110px 20px -110px 20px;
        }
        @media (max-width: 600px) {
            transform: scale(0.5);
            margin: -130px 20px -150px 20px;
        }
        @media (max-width: 500px) {
            margin: -130px 20px -150px 20px;
        }
    }
    .anychart-credits {
        display: none;
    }

    #title_grafic_bubbles {
        @include adaptive-font(36, 20);
    }
    .title_second_grafic {
        @include adaptive-font(16, 14);
        color: $dark-gray;
        font-size: 16px;
        font-weight: 400;
        margin: -30px 40px 0px 40px;
        max-width: 1000px;
        line-height: normal;
        @media (max-width: $tablet) {
            margin: 0px 20px 0px 20px;
        }
        text-align: left;
    }
    #title_back_grafic_bubbles {
        @include adaptive-font(90, 27);
    }
}
.js-logo {
    cursor: pointer;
}
.text-label {
    fill: $gray;
}
.y-axis text {
    font-size: 14px;
    font-weight: 400;
}
.x-axis text {
    fill: $dark-blue;
    font-size: 15px;
}
.grafic_container {
    border-radius: 10px;
    padding: 20px;
    margin: 0px 20px 0px 20px;
    display: flex;
    max-width: 1100px;
    position: relative;
    background-color: transparent;
    padding-right: 50px;

    .grafic_left {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0px;
        height: 270px;
        margin-top: 10px;
        position: absolute;
        top: 11px;
        left: 180px;
        z-index: 2;

        .grafic_left_gradient {
            width: 6px;
            height: 100%;
            background: linear-gradient(180deg, $red 0%, $yellow 46.35%, $greenGrafic 100%);
            border-radius: 12px;
            @media (max-width: $mobile) {
                width: 3px;
            }
        }
    }
    .xTitle {
        color: $gray;
        font-size: 14px;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    /* Стили для модального окна */
    .modal {
        display: none;
        position: fixed;
        z-index: 10;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 40px;
        @media (max-width: $mobile) {
            height: 97%;
        }
    }
    .modal::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(10px);
        visibility: visible;
        z-index: -1;
    }

    .modal_сontent {
        margin: 25% auto;
        padding: 20px;
        width: 80%;
        position: relative;
        z-index: 2;
        color: $gray;
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }
    .labelModal {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
    }

    .close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        cursor: pointer;
    }
}
.tooltip_bubbles {
    white-space: pre-line;
}
