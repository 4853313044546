@keyframes marquee {
  0% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.plug-item {
  text-align: center;
  min-width: 100%;
  animation: marquee 20s linear infinite;
}

@media only screen and (max-width: 1200px) {
  .plug-item {
    animation-duration: 15s;
  }
}

@media only screen and (max-width: 767px) {
  .plug-item {
    animation-duration: 10s;
  }
}

.notification-plug-wrapper:hover .plug-item {
  animation-play-state: paused;
}
.notification-plug-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Arial, sans-serif;
  font-weight: 400;
  line-height: 1;
  width: 100%;
  height: 40px;
  color: $white;
  background-color: $dark-blue;
  position: fixed;
  top: 77px;
  z-index: 9;
}

.plug_wrapper {
  overflow: hidden;
  min-height: 100vh;
  display: none;
  text-align: center;
  flex-direction: column;
  z-index: 10;
  position: relative;
  width: 100%;
  background-color: $dark;
  transition: all 0.2s linear;
}
.plug_wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  right: -500px;
  width: 770px;
  height: 770px;
  background: radial-gradient(65.67% 65.67% at 41.2% 34.33%, #e9a250 0%, rgba(255, 168, 0, 0) 100%);
  filter: blur(198.904px);
  border-radius: 778.731px;
  transform: rotate(-35.6deg);
  z-index: -100000;
  animation: yellow 40s infinite linear;
}
@keyframes yellow {
  0% {
    top: -100%;
  }
  50% {
    top: 100%;
  }
  100% {
    top: -100%;
  }
}
.plug_wrapper::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -500px;
  width: 600px;
  height: 600px;
  background: radial-gradient(65.67% 65.67% at 41.2% 34.33%, #5ca9ff 0%, #0f7af2 100%);
  filter: blur(198.904px);
  border-radius: 778.731px;
  transform: rotate(-35.6deg);
  z-index: -100000;
  animation: blue 40s infinite linear;
}
@keyframes blue {
  0% {
    top: 100%;
  }
  50% {
    top: -100%;
  }
  100% {
    top: 100%;
  }
}
.plug_content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  font-family: 'Noto Sans';
  color: $gray;
  font-weight: 400;
}
.plug_title {
  font-size: 36px;
  margin-bottom: 20px;
  line-height: 36px;
}
.plug_text {
  font-size: 20px;
  margin-bottom: 50px;
}
.plug_text_second {
  font-size: 20px;
  white-space: pre-wrap;
  // text-align: justify;
  margin: 0 auto;
  margin-bottom: 100px;
  width: 80%;
}
.plug_logo {
  width: 170px;
  height: 30px;
  margin-bottom: 50px;
}
.plug_image_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  img {
    width: 90%;
  }
}
.plug_language_container {
  position: absolute;
  right: 100px;
  top: 50px;
  @media (max-width: $tablet) {
    right: 5%;
    top: 40px;
  }
  @media (max-width: $mobile) {
    top: 80px;
  }
}

.ticker-wrap {
  display: none;
  width: 100%;
  overflow: hidden;
  height: 40px;
  background-color: $dark-blue;

  padding-left: 100%;
  box-sizing: content-box;

  $duration: 25s;

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker {
    display: inline-block;
    height: 40px;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;

    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;

    &__item {
      display: inline-block;

      padding: 10px 0;
      font-size: 16px;
      color: white;
    }
  }
}
