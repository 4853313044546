.not_found_wrapper {
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  background-color: $dark;
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 30px;

  .yellow_animation {
    position: absolute;
    animation: yellowcircle 15s infinite linear;
    left: 0;
    z-index: 10;
    pointer-events: none;
    height: 1000px;
    transform: scaleX(-1);
    filter: blur(15px);
    @media (max-width: $tablet) {
      animation: none;
    }
  }

  @keyframes yellowcircle {
    0% {
      top: -100%;
    }
    50% {
      top: 100%;
    }
    100% {
      top: -100%;
    }
  }

  .blue_animation {
    position: absolute;
    animation: bluecircle 15s infinite linear;
    top: 0;
    right: 0;
    z-index: 10;
    pointer-events: none;
    height: 1000px;
    filter: blur(15px);
    @media (max-width: $tablet) {
      animation: none;
    }
  }
  @keyframes bluecircle {
    0% {
      top: 100%;
    }
    50% {
      top: -100%;
    }
    100% {
      top: 100%;
    }
  }
  .not_found_content {
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      margin-bottom: 30px;
      max-width: 500px;
    }
    .not_found_logo {
      width: 180px;
      margin-bottom: 30px;
    }
    .title {
      color: $gray;
      font-size: 36px;
      margin-bottom: 30px;
    }
    .text {
      color: $gray;
      font-size: 18px;
      max-width: 550px;
      margin-bottom: 30px;
      text-align: center;
    }
    .not_found_button_wrapper {
      width: 190px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray;
      font-size: 18px;
      text-transform: uppercase;
      border: 1px solid $blue;
      border-radius: 40px;
      padding: 14px;
    }
    @media (max-width: $tablet) {
      img {
        max-width: 300px;
      }
      .not_found_logo {
        width: 140px;
        margin-bottom: 30px;
      }
      .title {
        font-size: 30px;
      }
      .text {
        color: $gray;
        font-size: 16px;
        max-width: 400px;
      }
    }
    @media (max-width: $mobile) {
      img {
        max-width: 250px;
      }
      .not_found_logo {
        margin-bottom: 30px;
      }
      .title {
        font-size: 25px;
        white-space: nowrap;
      }
      .text {
        font-size: 16px;
        max-width: 300px;
      }
    }
  }
}
