.select-languages {
    display: flex;
    background: transparent;
    cursor: pointer;
    position: relative;
    color: $gray;
    margin-right: 20px;
    z-index: 10;
    @media (max-width: 1200px) {
        margin-right: 20px;
    }

    .current {
        display: flex;
        gap: 7px;
        align-items: center;
        justify-content: center;

        svg {
            fill: $gray;
        }
    }

    .selection {
        position: absolute;
        top: 30px;
        left: -40px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        background: $dark;
        border: 1px solid $dark-light;
        opacity: 0.95;
        border-radius: 10px;
        padding: 10px 15px;
        display: none;
        color: $gray;

        .option {
            display: block;
            margin-bottom: 15px;
            white-space: nowrap;

            &:hover {
                color: $blue;
            }
        }
        .option:last-of-type {
            margin-bottom: 0px;
        }
        @media (max-width: $tablet) {
            left: -80px;
        }
    }

    &:hover .selection {
        visibility: visible;
    }

    @media (min-width: $tablet) {
        &:hover svg {
            fill: $blue;
        }

        &:hover span {
            color: $blue;
        }
    }
}

