.whitepaper_wrapper {
    padding: 100px 30px 50px 30px;
    background-color: $dark;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    .whitepaper_text {
        color: $gray;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        text-align: justify;
        max-width: 1000px;
        .whitepaper_title {
            color: $gray;
            text-transform: uppercase;
            text-align: center;
            font-weight: 700;
            margin: 40px 0;
            font-size: 22px;
        }
        .whitepaper_title_logo {
            margin: 40px auto;
            display: block;
            width: 200px;
        }
        p {
            margin-bottom: 20px;
        }
        ol {
            li {
                margin-bottom: 10px;
            }
            margin-left: 40px;
            margin-bottom: 20px;
            margin-top: 5px;
        }
        ul {
            li {
                margin-bottom: 5px;
                &:before {
                    width: 1em;
                    margin-left: -1em;
                    content: '\25CF';
                    font-size: 18px;
                    vertical-align: middle;
                    display: inline-block;
                    color: $blue;
                    margin-bottom: 5px;
                }
            }

            margin-left: 40px;
            margin-bottom: 20px;
        }
        .deep_ul {
            li::before {
                width: 1em;
                margin-left: -1em;
                margin-bottom: 5px;
                content: '\25CF';
                font-size: 18px;
                vertical-align: middle;
                display: inline-block;
                color: $dark-blue;
            }
        }
        .deep_deep_ul {
            li::before {
                margin-left: -1em;
                content: '\25CF';
                font-size: 14px;
                vertical-align: middle;
                display: inline-block;
                color: $gray;
            }
        }
        .persents_wrapper {
            display: flex;
            align-items: center;
            margin-top: 30px;
            .column {
                display: flex;
                flex-direction: column;
                p {
                    margin-bottom: 0px;
                }
            }
        }
        .percents {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 70px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            border: 1px solid $blue;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        .number_wrapper {
            display: flex;
            align-items: center;

            .number {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 32px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 1px solid $blue;
                margin-right: 10px;
            }
            p {
                margin-top: 15px;
            }
        }
        .ul_margin {
            margin-left: 80px;
        }

        .advantages_wrapper {
            margin: 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: $tablet) {
                flex-direction: column;
            }
            .advantage {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 20px;

                img {
                    width: 150px;
                    margin-right: 20px;
                }
            }
        }
    }
    .commission-table {
        width: 100%;
        border-collapse: collapse;
        @media screen and (max-width: $mobile) {
            font-size: 13px;
        }
    }

    .commission-table th,
    .commission-table td {
        border: 1px solid $blue;
        padding: 8px;
        text-align: center;
        @media screen and (max-width: $mobile) {
            padding: 4px;
        }
    }

    .commission-table th {
        background-color: $dark;

        color: $gray;
    }

    .commission-table td {
        background-color: $dark;
    }

    .commission-table .header-col {
        background-color: $dark;
    }
}
