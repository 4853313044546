.grafic_wrapper {
    width: 100%;
    background-color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
    .grafic {
        max-width: 1100px;
        width: 100%;
        height: 540px;
        border-radius: 10px;
        background-color: $dark-light;
        padding: 20px;
        margin: 20px;
        cursor: pointer;
        position: relative;
        @media (max-width: $tablet) {
            padding: 0px;
            margin: 0px;
        }
    }
    .anychart-credits {
        display: none;
    }
    path[stroke='#F3B619'] {
        filter: drop-shadow(3px 5px 15px #f3b619);
    }
    .ovg_legend {
        position: absolute;
        top: 20px;
        left: 123px;
        font-size: 20px;
        z-index: 1;
        display: flex;
        align-items: center;
        color: $gray;
        &::before {
            content: '';
            width: 17px;
            height: 17px;
            background-color: #f3b619;
            display: inline-block;
            margin-right: 5px;
        }
        @media (max-width: 1130px) {
            left: 60px;
        }
        @media (max-width: $mobile) {
            left: 40px;
        }
    }

        #title_grafic {
            @include adaptive-font(36, 18);
        }
        #title_back_grafic {
            @include adaptive-font(90, 28);
        }

}
.js-logo {
    cursor: pointer;
}
