.wrapper {
    background-image: url('../assets/images/background-plugin.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $dark;
    #title_back_plugin {
        @include adaptive-font(110, 65);
    }
    #title_plugin {
        @include adaptive-font(36, 30);
    }
}

.plugin_wrapper {
    color: $gray;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    width: 100%;
    height: 100%;
    @media (max-width: $tablet) {
        flex-direction: column-reverse;
        gap: 0px;
    }
    .plugin_left {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .text {
            text-align: center;
            font-size: 20px;
            max-width: 400px;
            margin-bottom: 50px;
            line-height: 30px;
            @media (max-width: $tablet) {
                margin-bottom: 30px;
                max-width: 550px;
            }
            @media (max-width: $mobile) {
                font-size: 16px;
                margin-bottom: 20px;
                max-width: 370px;
            }
        }
        a {
            color: $white;
        }
        .install_extention_button {
            display: block;
            background-color: transparent;
            color: $white;
            &:hover {
                background-color: $dark-blue;
            }
        }
    }
    .image_conteiner {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .image_background {
            filter: blur(90px);
            background: center url('../assets/images/backgroung-gradient-plugin.svg');
            width: 600px;
            height: 500px;
            position: absolute;
            @media (max-width: $mobile) {
                width: 400px;
                height: 300px;
            }
        }
        img {
            width: 500px;
            height: 400px;
            z-index: 2;
            @media (max-width: $mobile) {
                width: 350px;
                height: 260px;
            }
        }
    }
}
#plugin_title_wrapper {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    margin-top: 100px;
    margin-bottom: 30px;

    .title {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $gray;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 4;
        @include adaptive-font(36, 22);
    }
    .title_back {
        @include adaptive-font(110, 90);
        font-weight: 700;
        -webkit-text-stroke: 1.5px rgba(22, 188, 249, 0.7);
        text-transform: uppercase;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 0;
        background-clip: content-box;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(10, 14, 23, 0.8) 50%, rgba(10, 14, 23, 0) 100%);
            display: block;
            filter: blur(10px);
        }
    }
}
