$dark: #0a0e17;
$dark-light: #222a33;
$dark-gray: #6c829d;
$gray: #becde3;
$green: #21b654;
$blue: #16bdf9;
$red: #d93c3b;
$greenGrafic: #41d495;
$yellow: #e1b133;
$white: #ffffff;
$dark-blue: #1c7293;
$gold: linear-gradient(116deg, #c2ad7b 0%, #957742 30%, #ffe399 63%, #947c4f 100%);

$tablet: 950px;
$mobile: 550px;
