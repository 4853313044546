.standart_button {
    border: 1px solid $blue;
    width: 175px;
    height: 35px;
    background-color: transparent;
    border-radius: 30px;
    cursor: pointer;
    color: $gray;
    transition: background-color 0.3s ease;
    display: block;
    text-align: center;
    line-height: 33px;
}
.install_extention_button {
    background-color: $dark-blue;
    &:hover {
        background-color: $blue;
        color: $white;
    }
    @media (max-width: $tablet) {
        display: none;
    }
}
.wallet_link_button {
    color: $white;
    &:hover {
        background-color: $dark-blue;
    }
}
.more_details_button {
    width: 165px;
    height: 35px;
    border-color: $dark-gray;
    &:hover {
        background-color: $dark-light;
    }
    @media (max-width: $tablet) {
        align-self: flex-start;
    }
    @media (max-width: $mobile) {
        font-size: 13px;
    }
}
