.opportunities_wrapper {
    width: 100%;
    background-color: $dark;
    color: $gray;
    .opportunities {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0px;
        max-width: 1100px;
        margin: 0 auto;
        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            max-width: 640px;
        }

        .opportunity {
            text-align: center;
            margin-bottom: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 60px;

            .opportunity_image {
                @media (max-width: $mobile) {
                    width: 70px;
                    height: 70px;
                }
            }

            .opportunity_line {
                background: linear-gradient(
                    0deg,
                    rgba(22, 189, 249, 0.01) 0%,
                    #16bdf9 50%,
                    rgba(22, 189, 249, 0.01) 100%
                );
                width: 1px;
                height: 120px;
                margin: 0 15px;
                transform: rotate(10deg);
            }
            .opportunity_text {
                color: $dark-gray;
                text-align: left;
                margin-bottom: 10px;
                max-width: 280px;

                @media (max-width: $mobile) {
                    font-size: 12px;
                }
            }
        }
    }
}
#terminal_phone {
    @media (max-width: $mobile) {
        width: 70px;
        height: 95px;
    }
}
#title_back_opportunities {
    @include adaptive-font(110, 40);
}
