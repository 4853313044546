.rates {
    width: 100%;
    display: flex;
    align-items: center;

    justify-content: center;
    max-width: 1000px;
    gap: 70px;
    @media (max-width: $tablet) {
        margin-left: 60px;
    }
    @media (max-width: $mobile) {
        max-width: 400px;
        gap: 30px;
        margin-left: 20px;
    }
    @media (max-width: 420px) {
        max-width: 350px;
        margin-left: 0px;
    }

    .rate_wrapper {
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        min-width: 205px;
        @media (max-width: $tablet) {
            justify-content: flex-start;
        }
        @media (max-width: $mobile) {
            width: 130px;
            min-width: auto;
        }
        .rate_image {
            width: 50px;
            height: 50px;
            @media (max-width: $mobile) {
                width: 30px;
                height: 30px;
            }
        }

        .rate_info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            @media (max-width: $mobile) {
                gap: 0px;
            }

            .rate_text {
                color: $gray;
                font-size: 16px;
                white-space: nowrap;
                @media (max-width: $mobile) {
                    font-size: 10px;
                    margin-bottom: 3px;
                }
            }
            .rate_number {
                color: $blue;
                font-size: 24px;
                font-weight: 700;
                @media (max-width: $mobile) {
                    font-size: 16px;
                }
            }
        }
    }
}
