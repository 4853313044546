.main_info_screen_wrapper {
    background-image: url('../assets/images/background-rates.png');
    background-repeat: no-repeat;
    background-size: 100% 99%;
    background-color: $dark;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    position: relative;
    @media (max-width: $tablet) {
        background-size: cover;
    }
    .yellow_animation {
        position: absolute;
        animation: yellowcircle 15s infinite linear;
        left: 0;
        z-index: 10;
        pointer-events: none;
        height: 1000px;
        transform: scaleX(-1);
        filter: blur(15px);
        @media (max-width: $tablet) {
            animation: none;
        }
    }

    @keyframes yellowcircle {
        0% {
            top: -100%;
        }
        50% {
            top: 100%;
        }
        100% {
            top: -100%;
        }
    }

    .blue_animation {
        position: absolute;
        animation: bluecircle 15s infinite linear;
        top: 0;
        right: 0;
        z-index: 10;
        pointer-events: none;
        height: 1000px;
        filter: blur(15px);
        @media (max-width: $tablet) {
            animation: none;
        }
    }
    @keyframes bluecircle {
        0% {
            top: 100%;
        }
        50% {
            top: -100%;
        }
        100% {
            top: 100%;
        }
    }
    .main_info_screen {
        padding: 120px 30px 0px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 100px;
        max-width: 1100px;
        @media (max-width: $tablet) {
            gap: 20px;
        }
        @media (max-width: $tablet) {
            max-width: 800px;
        }
        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: $tablet) {
                flex-direction: column;
            }
            .text_wrapper {
                display: flex;
                flex-direction: column;
                color: $gray;
                max-width: 450px;
                @media (max-width: $tablet) {
                    max-width: 800px;
                }
                .gold_text {
                    background: var(--03, linear-gradient(118deg, #c2ad7b 0%, #957742 30%, #ffe399 63%, #947c4f 100%));
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 60px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    white-space: nowrap;
                    @media (max-width: $tablet) {
                        text-align: center;
                    }
                    @media (max-width: $mobile) {
                        font-size: 35px;
                    }
                }
                .description {
                    font-size: 35px;
                    line-height: 50px;
                    margin-bottom: 20px;
                    text-align: start;
                    @media (max-width: $tablet) {
                        font-size: 21px;
                        text-align: center;
                    }
                    @media (max-width: $mobile) {
                        font-size: 21px;
                    }
                }
            }
            .coins_image {
                width: 600px;
                height: 400px;
                @media (max-width: $tablet) {
                    width: 500px;
                    height: 300px;
                }
                @media (max-width: $mobile) {
                    width: 350px;
                    height: 230px;
                }
            }
        }
        a {
            margin-top: 30px;
            margin-bottom: 40px;
            width: 215px;
            height: 45px;
            line-height: 45px;
            font-size: 18px;
            @media (max-width: $tablet) {
                align-self: center;
            }
        }
        .description_second {
            color: $gray;
            font-size: 18px;
            line-height: 30px;
            @media (max-width: $mobile) {
                font-size: 16px;
            }
            @media (max-width: $tablet) {
                text-align: center;
            }
        }
    }
    .bottom_text {
        position: relative;
        padding: 30px;
        .description_second {
            margin-top: 30px;
            @media (max-width: $tablet) {
                text-align: start;
            }
        }
        .corner_top {
            position: absolute;
            left: 0;
            top: 0;
        }
        .corner_bottom {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
}
