.no_voting_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $gray;
    font-size: 18px;
    img {
        margin-bottom: 35px;
        @media (max-width: $mobile) {
            width: 290px;
        }
    }

    p {
        margin-bottom: 30px;
        text-align: center;
    }
}
