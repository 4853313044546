.services_wrapper {
    width: 100%;
    background-color: $dark;
    color: $gray;
    position: relative;

    .white_circle {
        position: absolute;
        left: 0;
        pointer-events: none;
        animation: white 20s infinite linear;
        pointer-events: none;
        @media (max-width: $tablet) {
            animation: none;
        }
    }
    @keyframes white {
        0% {
            top: -20%;
        }
        50% {
            top: 50%;
        }
        100% {
            top: -20%;
        }
    }

    .services {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0px;
        max-width: 1100px;
        margin: 0 auto;
        @media (max-width: $tablet) {
            grid-template-columns: 1fr;
            max-width: 540px;
        }
    }
    .service {
        padding: 10px;
        text-align: center;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        margin-bottom: 30px;
        @media (max-width: $tablet) {
            flex-direction: column;
        }
        @media (max-width: $mobile) {
            flex-direction: row-reverse;
            gap: 0px;
            margin-bottom: 0px;
        }
        .service_image {
            width: 270px;
            height: 210px;
            @media (max-width: $mobile) {
                width: 160px;
                height: auto;
            }
        }
        .service_image_cashback {
            width: 270px;
            height: 210px;
            @media (max-width: $mobile) {
                height: 140px;
            }
        }
        .service_image_fix {
            width: 330px;
            position: relative;
            margin-left: -40px;
            @media (max-width: $tablet) {
                margin-left: 0px;
            }
            @media (max-width: $mobile) {
                width: 200px;
                margin-right: -10px;
            }
        }
        .service_image_wallet {
            width: 310px;
            position: relative;
            margin-left: -30px;
            @media (max-width: $tablet) {
                margin-left: 0px;
            }
            @media (max-width: $mobile) {
                width: 200px;
                margin-right: -15px;
            }
        }
        .service_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            @media (max-width: $mobile) {
                max-width: 300px;
                justify-content: space-between;
            }
            img {
                max-width: 150px;
            }
            .service_content_title {
                height: 100%;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
                align-self: flex-start;
                margin-bottom: 5px;
                @media (max-width: $mobile) {
                    font-size: 16px;
                }
            }
            .staking_content_title {
                height: 30px;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
                align-self: flex-start;
                margin-bottom: 5px;
                @media (max-width: $mobile) {
                    font-size: 16px;
                }
            }

            .line {
                background: linear-gradient(225deg, rgba(22, 189, 249, 0.01) 0%, #16bdf9 100%);
                width: 100%;
                height: 2px;
                margin-bottom: 10px;
            }
            .service_content_text {
                color: $dark-gray;
                text-align: left;
                margin-bottom: 10px;
                @media (max-width: $mobile) {
                    font-size: 12px;
                }
            }
        }
    }
    #title_back_servives {
        @include adaptive-font(110, 65);
    }
    #title_servives {
        @include adaptive-font(36, 30);
    }
    .paralax_services {
        opacity: 0;
        transform: translateX(-30%);
        will-change: opacity, transform;
        transition:
            transform 1s,
            opacity 1s;
    }
    .paralax_services_right {
        opacity: 0;
        transform: translateX(30%);
        will-change: opacity, transform;
        transition:
            transform 1s,
            opacity 1s;
    }
    .visible {
        opacity: 1;
        transform: translateX(0);
    }
}
