.footer {
    width: 100%;
    color: $gray;
    background-color: $dark-light;
    .main_footer {
        max-width: 1000px;
        margin: 0 auto;
        padding: 35px;

        display: flex;
        justify-content: space-between;
        gap: 30px;
        @media (max-width: $tablet) {
            padding: 35px 10px;
        }
        @media (max-width: $mobile) {
            flex-direction: column;
            align-items: center;
        }
        a {
            color: $gray;
            cursor: pointer;
            :visited {
                color: $gray;
            }
            &:hover {
                color: $blue;
            }
        }
        img {
            width: 200px;
            @media (max-width: $tablet) {
                width: 170px;
            }
            @media (max-width: 650px) {
                width: 120px;
            }
            @media (max-width: $mobile) {
                width: 170px;
            }
        }

        .column {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            text-align: center;
            gap: 20px;
            text-transform: capitalize;
            @media (max-width: $mobile) {
                align-items: center;
            }
        }
        .telegram_wrapper {
            display: flex;
            gap: 5px;
            line-height: 18px;
            .telegram {
                width: 20px;
                height: 20px;
            }
        }
        .twitter_wrapper {
            display: flex;
            gap: 5px;
            line-height: 18px;
            .twitter {
                width: 20px;
                height: 20px;
            }
        }
    }
    .copyright {
        background-color: $dark;
        font-size: 12px;
        text-align: center;
        padding: 15px;
    }
}
