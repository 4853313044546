.burger_modal {
    width: 100vw;
    background-color: $dark-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(-120%);
    z-index: 10;
    transition: transform 0.5s;
    @media (min-width: $tablet) {
        display: none;
    }

    .burger_modal_navigation {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 80px 20px 0px 20px;
    }
    .nav-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        padding-bottom: 40px;
        text-transform: capitalize;
        a {
            color: $gray;
        }
        .wallet_link_button {
            color: $white;
        }
    }
    .close_burger_menu_button {
        cursor: pointer;
        width: 30px;
        height: 20px;
    }
}
.active_burger {
    transform: translateX(-50%) translateY(-20%);
}
.js-whitePaperBtn {
    color: $gray;
    background-color: transparent;
    &:hover {
        color: $blue;
    }
}
#js-faqBtn,
#js-faqBtnBurger,
#js-servicesBtn,
#js-votingBtn,
#js-votingBtnBurger {
    color: $gray;
    background-color: transparent;
    &:hover {
        color: $blue;
    }
}

#js-servicesBtn {
    color: $gray;
    background-color: transparent;
    &:hover {
        color: $blue;
    }
}
