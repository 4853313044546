.faq_wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1100px;
    color: $gray;
    margin-bottom: 70px;
    position: relative;

    .accordion {
        background-color: transparent;
        color: $gray;

        cursor: pointer;
        padding: 18px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        transition: 0.4s;

        font-weight: 700;
    }
    .line {
        opacity: 0.5;
        background: linear-gradient(90deg, rgba(190, 205, 227, 0) 0%, #becde3 54.17%, rgba(190, 205, 227, 0) 100%);
        width: 100%;
        height: 1px;
    }

    .active,
    .accordion:hover {
        background-color: transparent;
    }

    .accordion:after {
        content: '\002B';
        font-size: 25px;
        color: $blue;
        font-weight: bold;
        float: right;
        margin-left: 5px;
    }

    .active:after {
        content: '\2212';
    }

    .panel {
        padding: 0 18px;
        background-color: transparent;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
    }
}
#faq_start {
    margin-top: 130px;
    margin-bottom: 50px;
    .title {
        @include adaptive-font(36, 20);
    }
    .title_back {
        @include adaptive-font(90, 90);
    }
}
.faq_container {
    width: 100%;
    position: relative;
    .yellow_circle_animation {
        position: absolute;
        animation: yellow 15s infinite linear;
        top: -250%;
        right: 0;
        z-index: 0;
        pointer-events: none;
        height: 700px;
        filter: blur(15px);
        @media (max-width: $tablet) {
            animation: none;
        }
    }

    @keyframes yellow {
        0% {
            top: -400%;
        }
        50% {
            top: -40%;
        }
        100% {
            top: -400%;
        }
    }
}
