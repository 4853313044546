@import './null';
@import './common';
@import './font';
@import './vars';
@import './header';
@import './home';
@import './language';
@import './button';
@import './burgerModal';
@import './plug';
@import './rates';
@import './mainInfo';
@import './services';
@import './grafic';
@import './opportunities';
@import './plugin';
@import './mobileApp';
@import './noVoting';
@import './voting';
@import './faq';
@import './footer';
@import './whitepaper';
@import './graficBubbles';
@import './404';
body {
    font-family: 'Noto Sans', sans-serif;
    background-size: cover;
    background-color: $dark;
    scrollbar-color: $gray;
    scrollbar-width: 6px;
    overflow-x: hidden;
    min-height: 100vh;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: $dark-gray;
}

::-webkit-scrollbar-thumb {
    background-color: $dark-light;
    border-radius: 4px;
}

.title_wrapper {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    white-space: nowrap;
    margin-top: 100px;
    margin-bottom: 30px;

    .title {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $gray;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        z-index: 4;
        @include adaptive-font(36, 22);
    }
    .title_second {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $gray;
        text-align: center;
        z-index: 4;
        max-width: 800px;
        @media (max-width: $mobile) {
            top: 110%;
        }
    }
    .description_bubble {
        position: absolute;
        top: 105%;
        color: $gray;
        text-align: center;
        z-index: 4;
        max-width: 1050px;
        white-space: wrap;
        font-size: 16px;
    }
    .title_back {
        @include adaptive-font(110, 45);
        font-weight: 700;
        -webkit-text-stroke: 1.5px rgba(22, 188, 249, 0.7);
        text-transform: uppercase;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        z-index: 3;
        background-clip: content-box;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(10, 14, 23, 0.85) 50%, rgba(10, 14, 23, 0) 100%);
            display: block;
            filter: blur(10px);
        }
    }
}

@mixin adaptive-font($pcSize, $mobSize) {
    $addSize: $pcSize - $mobSize;
    $maxWidth: $maxWidth - 320;
    font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth}));
}

.arrow_to_top_wrapper {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid $blue;
    background-color: $dark-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 5%;
    bottom: 100px;
    z-index: 3;
    cursor: pointer;
    opacity: 0;
    transition:
        opacity 0.1s ease,
        background-color 0.5s ease;
    &:hover {
        background-color: $blue;
    }
    img {
        transform: rotate(180deg);
        width: 25px;
    }
    @media (max-width: 1300px) {
        display: none;
    }
    @media (max-width: 1400px) {
        right: 2%;
    }
}
.all_content_wrapper {
    overflow-x: hidden;
}

// .giweaway_wrapper {
//     background-image: url('../assets/images/giweaway.png');
//     background-size: contain;
//     background-position: center top;
//     background-repeat: no-repeat;
//     height: 100vh;
//     width: 100vw;
// }
.giweaway_wrapper {
    background-image: url('../assets/images/giweaway.png');
    background-size: 100% 100%;
    min-height: 100vh;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
